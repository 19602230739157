import MetaDatos from "utils/MetaDatos";
import Footer from "components/organisms/Footer/FooterBlock";
import PropTypes from "prop-types";
import Header from "components/organisms/Header";

const Layout = (props) => {
  return (
    <>
      <MetaDatos
        title={props.title}
        description={props.description}
        keywords={props.keywords}
        image={props.image}
        inmueble={props.inmueble}
        action={props.actions}
        name={props.name}
        descripProperty={props.descripProperty}
        url={props.url}
        price={props.price}
        currency={props.currency}
      />
      <Header footerMenu={props.footerMenu} mainMenu={props.mainMenu} isHome={props.isHome}/>
      <div id="layout">{props.children}</div>
      <Footer footerMenu={props.footerMenu?.items} />
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  image: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  mainMenu: PropTypes.object.isRequired,
  footerMenu: PropTypes.object.isRequired
};

export default Layout;
