import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

export default function SocialNetwork({facebook, linkedin, instagram, classNameLi = "", classNameAvatar = "", classNameIcon = "", avatarSx = {}}) {


  return (
    <>
      <li className={classNameLi}>
        <a href={ facebook?.url } aria-label={'facebook-social-network'} target="_blank" rel="nofollow noreferrer">
          <div style={avatarSx} className={classNameAvatar}>
            <FaFacebookF className={classNameIcon}/>
            <span className="hidden">{ facebook?.title }</span>
          </div>
        </a>
      </li>
      <li className={classNameLi}>
        <a href={linkedin?.url} aria-label={'linkedin-social-network'} target="_blank" rel="nofollow noreferrer">
          <div style={avatarSx} className={classNameAvatar}>
            <FaLinkedinIn className={classNameIcon}/>
            <span className="hidden">{linkedin?.title}</span>
          </div>
        </a>
      </li>
      <li className={classNameLi}>
        <a href={ instagram?.url } aria-label={'instagram-social-network'} target="_blank" rel="nofollow noreferrer">
          <div style={avatarSx} className={classNameAvatar}>
            <FaInstagram className={classNameIcon}/>
            <span className="hidden">{ instagram?.title }</span>
          </div>
        </a>
      </li>
    </>
  )
}


export function StaticDataSN({facebook, linkedin, instagram}) {

  return (
    <>
      <ul>
        <li>
          <a href={facebook?.url} aria-label={'facebook-social-network'} target="_blank" rel="nofollow noreferrer">
            { facebook?.title }
          </a>
        </li>
        <li>
          <a href={linkedin?.url} aria-label={'linkedin-social-network'} target="_blank" rel="nofollow noreferrer">
            { linkedin?.title }
          </a>
        </li>
        <li>
          <a href={instagram?.url} aria-label={'instagram-social-network'} target="_blank" rel="nofollow noreferrer">
            { instagram?.title }
          </a>
        </li>
      </ul>
    </>
  )
}
