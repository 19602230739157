export const data = [
  {
    title: "Propiedades populares",
    links: [
      {
        textLink: "Casas en venta",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-pagina-1",
      },
      {
        textLink: "Departamentos en venta",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-pagina-1",
      },
      {
        textLink: "Casa en venta",
        url: "https://tucasamicasa.com/buscar/casa-en-renta-pagina-1",
      },
      {
        textLink: "Departamento en renta",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-pagina-1",
      },
      {
        textLink: "Terreno en venta",
        url: "https://tucasamicasa.com/buscar/terreno-en-venta-pagina-1",
      },
    ],
  },
  {
    title: "Casas en venta",
    links: [
      {
        textLink: "Casa en venta en Querétaro",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-queretaro-pagina-1",
      },
      {
        textLink: "Casa en venta en El Marques, Querétaro",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-el-marques-estado-queretaro-pagina-1",
      },
      {
        textLink: "Casa en venta en Cuernavaca",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-cuernavaca-estado-morelos-pagina-1",
      },
      {
        textLink: "Casa en venta en Valle de Bravo",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-valle-de-bravo-estado-de-mexico-pagina-1",
      },
      {
        textLink: "Casa en venta en Álvaro Obregón",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-alvaro-obregon-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Casa en venta en Huixquilucan",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-huixquilucan-estado-de-mexico-pagina-1",
      },
      {
        textLink: "Casa en venta en Atizapan de Zaragoza",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-atizapan-de-zaragoza-estado-de-mexico-pagina-1",
      },
      {
        textLink: "Casa en venta en Torreón",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-torreon-estado-coahuila-pagina-1",
      },
      {
        textLink: "Casa en venta en Merida",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-merida-estado-yucatan-pagina-1",
      },
      {
        textLink: "Casa en venta en Tijuana",
        url: "https://tucasamicasa.com/buscar/casa-en-venta-en-tijuana-estado-baja-california-pagina-1",
      },
    ],
  },
  {
    title: "Departamentos en renta",
    links: [
      {
        textLink: "Departamento en renta en Ciudad de México",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en renta en Miguel Hidalgo",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-miguel-hidalgo-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en renta en Huixquilucan",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-huixquilucan-estado-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en renta en Álvaro Obregón",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-alvaro-obregon-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en renta en Querétaro",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-queretaro-estado-queretaro-pagina-1",
      },
      {
        textLink: "Departamento en renta en Cuahutemoc",
        url: "https://tucasamicasa.com/buscar/departamento-en-renta-en-cuauhtemoc-estado-ciudad-de-mexico-pagina-1",
      },
    ],
  },
  {
    title: "Departamentos en venta",
    links: [
      {
        textLink: "Departamento en venta en Ciudad de México",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en venta en Benito Juárez",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-benito-juarez-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en venta en Cuahutemoc",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-cuauhtemoc-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en venta en Miguel Hidalgo",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-miguel-hidalgo-estado-ciudad-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en venta en Huixquilucan",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-huixquilucan-estado-de-mexico-pagina-1",
      },
      {
        textLink: "Departamento en venta en Tulum",
        url: "https://tucasamicasa.com/buscar/departamento-en-venta-en-tulum-estado-quintana-roo-pagina-1",
      },
    ],
  },
];

export function staticData() {
  return (
    <>
      {data.map((item, index) => (
        <section name={item.title} key={index} style={{ margin: '10px 0' }}>
          <h4>{item.title}</h4>
          <ul style={{ padding: '10px' }}>
            {item.links.map((link, index) => (
              <li key={index} style={{ marginBottom: '5px' }}>
                <a href={link.url}>{link.textLink}</a>
              </li>
            ))}
          </ul>
        </section>
      ))}
    </>
  )
}
