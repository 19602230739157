import Navbar from './Navbar';


const Header = ({mainMenu, footerMenu, isHome}) => {

  return (
    <header className="2xl:max-w-[1920px] 2xl:m-auto">
      <Navbar mainMenu={mainMenu} footerMenu={footerMenu} isHome={isHome} />
    </header>
  );
};

export default Header;
