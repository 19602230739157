import Logo from "../atoms/Logo";
import {useState} from "react";
import { InView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { CircularProgress, Skeleton } from '@mui/material';

const DCustomIcon = dynamic(() => import('../atoms/CustomIcon'),{
  ssr: false,
  loading: () => (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={30}
      height={30}
    />
  )
});

const Menu = dynamic(() => import('../molecules/Menu'),{
  ssr: false,
});

function Nav({mainMenu, footerMenu, isHome}) {
  const [navbar, setNavbar] = useState(true);
  const home = isHome;

  return (
    <div className={`relative z-50 flex justify-between items-center max-w-full px-[5%] flex-wrap w-full ${home ? 'bg-transparent' : 'bg-white'}`}>
      <Logo isHome={home}/>
      <div className="xl:hidden h-8 block cursor-pointer">
        <DCustomIcon iconName="menu" viewBox="0 0 50 50" className={`${home ? 'text-white' : 'text-green-soft'} text-3xl`} onClick={() => setNavbar(!navbar)}/>
      </div>
      <nav className={`${navbar ? "hidden" : 'block'} xl:block w-full xl:w-auto`}>
        <div className='w-full justify-center md:items-center flex'>
          <InView triggerOnce>
            {({ inView, ref, entry }) => (
              <div ref={ref}>
                <div className="hidden">
                  {/*{ staticData() }*/}
                </div>
                { inView && (
                  <Menu mainMenu={mainMenu} footerMenu={footerMenu} isHome={isHome}/>
                )}
              </div>
            )}
          </InView>
        </div>
      </nav>
    </div>
  )
}



export default Nav
