import FooterBlock from "./FooterBlock.json";
import Image from 'next/image';
import logo from "public/img/TCMC_Portal_inmobiliario.png"
import { InView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { staticData } from '/components/molecules/data';
import { StaticDataSN } from '/components/molecules/SocialNetwork';
import { CircularProgress } from '@mui/material';

const SocialNetwork = dynamic(() => import('components/molecules/SocialNetwork'), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false
});

const Facets = dynamic(() => import("components/molecules/Facets"), {
  loading: () => (
    <div 
    className="box-border h-full w-screen bg-gray-light text-gray-light">
      { staticData() }
    </div>
  ),
  ssr: false
});

export default function Footer({ footerMenu, copyrightBlock = FooterBlock.copyrightBlock }) {

  const facebook = footerMenu?.at(5)
  const linkedin = footerMenu?.at(6)
  const instagram = footerMenu?.at(7)
  // const telephone = footerMenu?.at(1)
  // const email = footerMenu?.at(2)
  // const address = footerMenu?.at(3)

  return(
    <div 
    className="2xl:max-w-[1920px] 2xl:m-auto">
      <InView triggerOnce>
        {({ inView, ref, entry }) => (
          <div ref={ref}>
            <div className="hidden">
              { staticData() }
            </div>
            { // Para SSR necesitamos pintar una caja respetando el tamaño de el footer para mejorar el performance de CLS
              !inView && (
                <div 
                className="box-border h-full w-full bg-gray-light text-gray-light">
                  { staticData() }
                </div>
              )
            }
            { inView && (
              <Facets/>
            )}
          </div>
        )}
      </InView>
      <footer 
      className="h-full md:text-sm lg:text-base text-center">
        <div 
        className="flex flex-col gap-3 items-center bg-gray-light pb-4">
          {/* Logo container */}
          <div 
          className='mx-3'>
            <a href={ "/" } 
            target="_self" 
            rel="noreferrer">
              <Image 
              width={400} 
              height={71} 
              src={logo} 
              alt="tucasamicasa" 
              placeholder="blur"/>
            </a>
          </div>
          {/* Social network container */}
          <InView triggerOnce >
            {({ inView, ref, entry }) => (
              <div ref={ref}>
                <div className="hidden">
                  <StaticDataSN facebook={facebook} linkedin={linkedin} instagram={instagram} />
                </div>
                { inView && (
                  <ul className="flex justify-around gap-6 ">
                    <SocialNetwork
                      facebook={facebook}
                      linkedin={linkedin}
                      instagram={instagram}
                      classNameAvatar="bg-gray hover:bg-red p-2 rounded-full"
                      classNameIcon="text-white text-2xl"
                    />
                  </ul>
                )}
              </div>
            )}
          </InView>
        </div>
        <div className="bg-black">
          <p className="text-white font-medium p-3">{ copyrightBlock.body }</p>
        </div>
      </footer>
    </div>
  )
}
